import {Typography} from "@material-ui/core/index";
import Grid from "@material-ui/core/Grid/index";
import React from "react";
import WeTheBestArea from "./WeTheBestArea";
import {FormattedMessage} from "react-intl";

const WeTheBest = () => {
  return (
    <Grid item xs={12} className={'we-the-best'}>

      <Typography variant={'h2'}>
        <FormattedMessage id="footer-the-best" />
      </Typography>

      <Grid container>
        <WeTheBestArea/>
      </Grid>
    </Grid>
  )
}

export default WeTheBest
