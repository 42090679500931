import React from "react";
import {FormattedMessage} from "react-intl";
import {LazyLoadImage} from "react-lazy-load-image-component";


const paymentsImage = require.context('./../../../../../assets/images/icons', true);

const LoadImg = ({index}) => {
  const img = paymentsImage(`./${index}.png`).default;
  return (
    <LazyLoadImage
      src={img}
      effect={'blur'}
    />
  )
}

const weTheBestsArray = [
  {
    title: <FormattedMessage id="footer-the-best-1-title" />,
    icon: <LoadImg index={1}/>,
    description: <FormattedMessage id="footer-the-best-1-description" />
  },
  {
    title: <FormattedMessage id="footer-the-best-2-title" />,
    icon: <LoadImg index={2}/>,
    description: <FormattedMessage id="footer-the-best-2-description" />
  },
  {
    title: <FormattedMessage id="footer-the-best-3-title" />,
    icon: <LoadImg index={3}/>,
    description: <FormattedMessage id="footer-the-best-3-description" />
  },
  {
    title: <FormattedMessage id="footer-the-best-4-title" />,
    icon: <LoadImg index={4}/>,
    description: <FormattedMessage id="footer-the-best-4-description" />
  },
  {
    title: <FormattedMessage id="footer-the-best-5-title" />,
    icon: <LoadImg index={5}/>,
    description: <FormattedMessage id="footer-the-best-5-description" />
  },
  {
    title: <FormattedMessage id="footer-the-best-6-title" />,
    icon: <LoadImg index={6}/>,
    description: <FormattedMessage id="footer-the-best-6-description" />
  },
  {
    alert: true,
    title: <FormattedMessage id="footer-the-best-7-title" />,
    icon: <LoadImg index={7}/>,
    description: <FormattedMessage id="footer-the-best-7-description" />
  }
];

export default weTheBestsArray
