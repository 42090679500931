import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';

// sample page routing
const HomePage = Loadable(lazy(() => import('../views/pages/home')));
const CategoryPage = Loadable(lazy(() => import('../views/pages/category')));
const ProductsPage = Loadable(lazy(() => import('../views/pages/products')));
const PrivacyPolicyPage = Loadable(lazy(() => import(`../views/pages/privacy-policy`)));
const TermsPage = Loadable(lazy(() => import(`../views/pages/terms`)));
const WaitPage = Loadable(lazy(() => import('../views/pages/wait')));
const SuccessPage = Loadable(lazy(() => import('../views/pages/wait/success')));
const ErrorPage = Loadable(lazy(() => import('../views/pages/wait/error')));
const OrdersPage = Loadable(lazy(() => import('../views/pages/orders')));
const FaqPage = Loadable(lazy(() => import('../views/pages/faq')));
const ContactsPage = Loadable(lazy(() => import('../views/pages/contacts')));
const PayPage = Loadable(lazy(() => import('../views/pages/pay')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();
    return (
        <Route path={['/privacy_policy', '/terms', '/wait', '/success', '/error', '/orders', '/faq', '/contacts', '/']}>
            <MainLayout path={location.pathname}>
                <Switch location={location} key={location.pathname}>
                    {/*<AuthGuard>*/}
                    <Route path="/wait" component={WaitPage} />
                    <Route path="/success" component={SuccessPage} />
                    <Route path="/error" component={ErrorPage} />
                    <Route path="/privacy_policy" component={PrivacyPolicyPage} />
                    <Route path="/terms" component={TermsPage} />
                    <Route path="/orders" component={OrdersPage} />
                    <Route path="/faq" component={FaqPage} />
                    <Route path="/contacts" component={ContactsPage} />
                    <Route path="/pay" component={PayPage} />
                    <Route path="/:categoryId/:productId" component={ProductsPage} />
                    <Route path="/:categoryId" component={CategoryPage} />
                    <Route path="/" component={HomePage} />
                    {/*</AuthGuard>*/}
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
