import React from 'react';
// import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    ClickAwayListener,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Popper,
    Typography,
    useMediaQuery
} from '@material-ui/core';

// project imports
import Transitions from '../../../../ui-component/extended/Transitions';
import * as actionTypes from '../../../../store/actions';

//assets
import TranslateTwoToneIcon from '@material-ui/icons/TranslateTwoTone';
import getInitialLocal from "../../../../utils/getInitialLocal";

const useStyles = makeStyles((theme) => ({
    navContainer: {
        width: '100%',
        minWidth: '200px',
        maxWidth: '280px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '250px'
        }
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        color: theme.palette.primary.dark,
        transition: 'all .2s ease-in-out',
        '&[aria-controls="menu-list-grow"],&:hover': {
            borderColor: theme.palette.primary.main,
            background: theme.palette.primary.main,
            color: theme.palette.primary.light
        }
    },
    box: {
        marginLeft: '16px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '8px'
        }
    }
}));

const localOptions = {
    'uk': {
        name: 'Українська',
        code: 'UA'
    },
    'ru': {
        name: 'Русский',
        code: 'RU'
    },
    'pl': {
        name: 'Polski',
        code: 'PL'
    },
    'en': {
        name: 'English',
        code: 'EN'
    },
}

//-----------------------|| LOCALIZATION ||-----------------------//

const LocalizationSection = () => {
    const classes = useStyles();
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [language, setLanguage] = React.useState(getInitialLocal(customization));

    const handleListItemClick = (event, index) => {
        setLanguage(index);
        dispatch({ type: actionTypes.THEME_LOCALE, locale: index });
        setOpen(false);
        localStorage.setItem("language", index);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    // React.useEffect(() => {
    //     setLanguage(getInitialLocal(customization));
    //         localStorage.setItem("language", language);
    // }, [customization]);

    const LocalsArea = () => (process?.env?.REACT_APP_LOCALES?.split(', ') ?? []).map(local => <LocalView code={local}/>)
    const LocalView = ({code}) => (
      <ListItem button selected={language === code} onClick={(event) => handleListItemClick(event, code)}>
          <ListItemText
            primary={
                <Grid container>
                    <Typography color="textPrimary">{localOptions[code]?.name}</Typography>
                    <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                        ({localOptions[code]?.code})
                    </Typography>
                </Grid>
            }
          />
      </ListItem>
    );

    return (
        <React.Fragment>
            <Box component="span" className={classes.box}>
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        className={classes.headerAvatar}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        {language !== 'en' && (
                            <Typography variant="h5" sx={{ textTransform: 'uppercase' }} color="inherit">
                                {language}
                            </Typography>
                        )}
                        {language === 'en' && <TranslateTwoToneIcon sx={{ fontSize: '1.3rem' }} />}
                    </Avatar>
                </ButtonBase>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom-start' : 'bottom'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 0 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps, placement }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper elevation={16}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <List component="nav" className={classes.navContainer}>
                                    <LocalsArea/>
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </React.Fragment>
    );
};

export default LocalizationSection;
