import React from "react";
import helpCenterArray from "./helpCenterArray";
import Grid from "@material-ui/core/Grid/index";

const HelpCenterArea = () => helpCenterArray.map((one) => (<HelpCenterView data={one}/>));
const HelpCenterView = ({data}) => (
  <Grid item xs={12} md={4} style={{padding: '0 10px'}}>
    <a href={data.link} className={`help-center-view`}>
      <div>
        {data.icon}
      </div>
      <div>
        {data.title}
      </div>
    </a>
  </Grid>
);

export default HelpCenterArea
