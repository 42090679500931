import React from "react";
import howWorkArray from "./howWorkArray";
import Grid from "@material-ui/core/Grid/index";

const HowWorkArea = () => howWorkArray.map((one, item) => (<HowWorkView data={one} item={item}/>));
const HowWorkView = ({data, item}) => (
  <Grid item xs={12} md={4} style={{padding: '0 10px'}}>
    <div className={`how-work-view`}>
      <p>{data.title}</p>
      <div>
        {(item >= 9) ? item + 1 : `0${item + 1}`}
      </div>
      <p>{data.description}</p>
    </div>
  </Grid>
);

export default HowWorkArea
