import weTheBestsArray from "./weTheBestsArray";
import Grid from "@material-ui/core/Grid/index";
import React from "react";

const WeTheBestArea = () => weTheBestsArray.map((one) => (<WeTheBestView data={one}/>));
const WeTheBestView = ({data}) => (
  <Grid item xs={12} md={4} style={{padding: '0 10px'}}>
    <div className={`we-the-best-view ${data.alert && 'we-the-best-view-alert'}`}>
      <div>
        {data.icon}
      </div>
      <p>{data.title}</p>
      <p>{data.description}</p>
    </div>
  </Grid>
);

export default WeTheBestArea
