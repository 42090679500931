// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {IconHeadset, IconHelp, IconHome, IconLicense, IconList} from '@tabler/icons';

// constant
const icons = {
    IconLicense: IconLicense,
    IconHome: IconHome,
    IconHelp: IconHelp,
    IconHeadset: IconHeadset,
    IconList: IconList
};

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/',
            icon: icons['IconHome'],
            breadcrumbs: false,
        },
        {
            id: 'orders',
            title: <FormattedMessage id="orders" />,
            type: 'item',
            url: '/orders',
            icon: icons['IconList'],
            breadcrumbs: true,
        },
        {
            id: 'privacy-policy',
            title: <FormattedMessage id="privacy-policy" />,
            type: 'item',
            url: '/privacy_policy',
            icon: icons['IconLicense'],
            breadcrumbs: true,
            hide: true
        },
        {
            id: 'terms',
            title: <FormattedMessage id="terms" />,
            type: 'item',
            url: '/terms',
            icon: icons['IconLicense'],
            breadcrumbs: true,
            hide: true
        },
        {
            id: 'faq',
            title: <FormattedMessage id="faq" />,
            type: 'item',
            url: '/faq',
            icon: icons['IconHelp'],
            breadcrumbs: true,
            // target: true
        },
        {
            id: 'contacts',
            title: <FormattedMessage id="contacts" />,
            type: 'item',
            url: '/contacts',
            icon: icons['IconHeadset'],
            breadcrumbs: true,
        },

        {
            id: 'orders',
            title: <FormattedMessage id="orders" />,
            // caption: <FormattedMessage id="orders" />,
            type: 'collapse',
            hide: true,
            url: '/orders',
            children: [
                {
                    id: 'wait',
                    title: <FormattedMessage id="wait" />,
                    type: 'item',
                    url: '/wait',
                    breadcrumbs: true,
                    hide: true,
                },
                {
                    id: 'error',
                    title: <FormattedMessage id="error" />,
                    type: 'item',
                    url: '/error',
                    breadcrumbs: true,
                    hide: true,
                },
                {
                    id: 'success',
                    title: <FormattedMessage id="success" />,
                    type: 'item',
                    url: '/success',
                    breadcrumbs: true,
                    hide: true,
                }
            ]
        },



        // {
        //     id: 'documentation',
        //     title: <FormattedMessage id="documentation" />,
        //     type: 'item',
        //     url: 'https://codedthemes.gitbook.io/berry/',
        //     icon: icons['IconHelp'],
        //     external: true,
        //     target: true
        // },
        // {
        //     id: 'roadmap',
        //     title: <FormattedMessage id="roadmap" />,
        //     type: 'item',
        //     url: 'https://codedthemes.gitbook.io/berry/roadmap',
        //     icon: icons['IconSitemap'],
        //     external: true,
        //     target: true
        // }
    ]
};
