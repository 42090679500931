import React from "react";
import Grid from "@material-ui/core/Grid";
import {FormattedMessage} from "react-intl";
import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import WeTheBest from "../../../views/pages/helpers/footer/we-the-best/WeTheBest";
import HowWork from "../../../views/pages/helpers/footer/how-work/HowWork";
import HelpCenter from "../../../views/pages/helpers/footer/help-center/HelpCenter";

const paymentsImage = require.context('./../../../assets/images/payments', true);

const Footer = () => {
  let paymentsLink = paymentsImage(`./${process.env.REACT_APP_PAYMENTS_LOGO}.png`).default;
  const dateNow = new Date();

  return (
    <React.Fragment>
      <Grid container spacing={2} className={'footer'}>
        {(process.env.REACT_APP_HOWWORK) && (<Grid xs={12} item container>
          <HowWork />
        </Grid>)}

        {(process.env.REACT_APP_HOWWORK) && (<Grid xs={12} item container>
          <WeTheBest />
        </Grid>)}

        <Grid xs={12} item container>
          <HelpCenter />
        </Grid>

        <Grid item xs>
          <p>
            {process.env.REACT_APP_NAME} © 2017-{dateNow.getFullYear()}. <FormattedMessage id="footer-copyright" />
          </p>
          <p>
            <a href={'/privacy_policy'} className={'footer-link'} >
              <FormattedMessage id="privacy-policy" />
            </a>
            <span> | </span>
            <a href={'/terms'} className={'footer-link'} >
              <FormattedMessage id="terms" />
            </a>
          </p>
        </Grid>
        <Grid item xs container alignItems="center" className={'footer-payments'} >
          <LazyLoadImage
            src={paymentsLink}
            effect={'blur'}
            width={320}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );

};

export default Footer;
