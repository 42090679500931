import {Typography} from "@material-ui/core/index";
import Grid from "@material-ui/core/Grid/index";
import React from "react";
import {FormattedMessage} from "react-intl";
import HelpCenterArea from "./HelpCenterArea";

const HelpCenter = () => {
  return (
    <Grid item xs={12} className={'help-center'}>

      <Typography variant={'h2'}>
        <FormattedMessage id="footer-help-center" />
      </Typography>

      <Grid container>
        <HelpCenterArea/>
      </Grid>
    </Grid>
  )
}

export default HelpCenter
