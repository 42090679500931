import {Avatar, Box, ButtonBase, makeStyles} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {IconNotebook} from "@tabler/icons";

// style constant
const useStyles = makeStyles((theme) => ({
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: 'all .2s ease-in-out',
    background: '#29314f',
    color: '#7c4dff',
    width: '100%',
    padding: '0 10px',
    '&[aria-controls="menu-list-grow"],&:hover, span:hover': {
      background: '#7c4dff',
      color: '#fff'
    },
    '& span': {
      color: '#b3bde5',
      fontSize: 14,
      paddingLeft: 5
    }
  },
}));

const HelpSection = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box component="span" className={classes.box}>
        <ButtonBase sx={{ borderRadius: '12px' }} href='/orders'>
          <Avatar
            variant="rounded"
            className={classes.headerAvatar}
            aria-haspopup="true"
            // onClick={() => {
            //   const newWindow = window.open('https://t.me/InstaBoost_TOP', '_blank', 'noopener,noreferrer')
            //   if (newWindow) newWindow.opener = null;
            // }}
            color="inherit"
          >
            <IconNotebook stroke={1.5} size="1.3rem" className={'menu-top-orders'} />
            <span>
              <FormattedMessage id="orders" />
            </span>
          </Avatar>
        </ButtonBase>
      </Box>
    </React.Fragment>
  );

};

export default HelpSection;
