import React from "react";
import {FormattedMessage} from "react-intl";
import {IconHelp, IconLifebuoy, IconNotebook} from "@tabler/icons";

const helpCenterArray = [
  {
    title: <FormattedMessage id="footer-help-center-1-title" />,
    icon: <IconNotebook />,
    link: '/orders'
  },
  {
    title: <FormattedMessage id="footer-help-center-2-title" />,
    icon: <IconHelp />,
    link: '/faq'
  },
  {
    title: <FormattedMessage id="footer-help-center-3-title" />,
    icon: <IconLifebuoy />,
    link: '/contacts'
  }
];

export default helpCenterArray
