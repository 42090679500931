import React from "react";
import {FormattedMessage} from "react-intl";

const howWorkArray = [
  {
    title: <FormattedMessage id="footer-how-work-1-title" />,
    description: <FormattedMessage id="footer-how-work-1-description" />
  },
  {
    title: <FormattedMessage id="footer-how-work-2-title" />,
    description: <FormattedMessage id="footer-how-work-2-description" />
  },
  {
    title: <FormattedMessage id="footer-how-work-3-title" />,
    description: <FormattedMessage id="footer-how-work-3-description" />
  }
];

export default howWorkArray
